import { createApp } from "vue";
import router from './router'
import { createAuth0 } from '@auth0/auth0-vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuestionCircle,
  faExclamationCircle,
  faCheckCircle,
  faPlusCircle,
  faMinusCircle,
  faFolderOpen,
  faSave,
  faPlay,
  faPause,
  faStop,
  faSignOutAlt, 
  faFileDownload, 
  faBan,
  faEye, 
  faExclamationTriangle,
  faGear, 
  faScrewdriverWrench,
  faEllipsis,
  faFileImport,
  faFileCircleExclamation, 
  faTrash,
  faLightbulb,
  faArrowPointer,
  faBook,
  faCommentDots,
  faCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faQuestionCircle,
  faExclamationCircle,
  faCheckCircle,
  faPlusCircle,
  faMinusCircle,
  faFolderOpen,
  faSave,
  faPlay,
  faPause,
  faStop,
  faSignOutAlt, 
  faFileDownload,
  faBan,
  faEye,
  faExclamationTriangle,
  faGear,
  faScrewdriverWrench,
  faEllipsis,
  faFileImport, 
  faFileCircleExclamation,
  faTrash,
  faLightbulb,
  faArrowPointer,
  faBook,
  faCommentDots,
  faCircle
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import the i18n plugin
import { createI18n } from 'vue-i18n';

import App from './App.vue'
import Interface from "./components/Interface.vue";

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Interface", Interface)
app.use(router);

// by adding the audience parameter, we get a JWT token back
app.use(
  createAuth0({
    domain: "dapta-dev1.eu.auth0.com",
    client_id: "821uCiX0nDPNQbI7VW0kahEbsoRRtHp5",
    redirect_uri: window.location.origin, 
    audience: "http://localhost:4242"
  })
);

app.provide('back_end_user_services_API_end_point', 'https://api.daptasim.com/api/v1/orchestrator')
app.provide('back_end_admin_API_end_point', 'https://api.daptasim.com/be-api/v1')
app.provide('auth_API_end_point', 'https://api.daptasim.com/auth-api/v1')

// START = add new codes for implementing the change language feature
import en from './lang/en.json';
import zh from './lang/zh.json';

const i18n = createI18n({
  legacy: false, // Set to `true` if you're using Vue 2.x
  locale: 'en', // Default locale
  messages: {
    en,
    zh,
  },
});

app.use(i18n);
// END = add new codes for implementing the change language feature

app.mount('#app');