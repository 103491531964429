<template>

  <section class="dropdownMenu">
      <slot/>
  </section>

</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.dropdownMenu {
  position: fixed;
  width: 180px;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 3px 3px 0 0 rgba(black,.1);
  background: white;
  padding: 0;
  animation: menu 0.3s ease forwards;
  z-index: 10;

  }

</style>