<template>
  <form @submit.prevent="submitForm">
    <tabs-wrapper>
      <tab title="Properties">
        <div
          class="form-control"
          :class="{ invalid: validators.label === 'invalid' }"
        >
          <label for="connection-name"> Connection Label </label>
          <input
            id="connection-name"
            name="connection-name"
            type="text"
            v-model.trim="connection.label"
            @blur="validateInput(connection.label, 'label')"
          />
          <div class="form-warning" v-if="validators.label === 'invalid'">
            Please enter a valid Name.
          </div>
        </div>
        <div
          class="form-control"
          :class="{ invalid: validators.label === 'invalid' }"
        >
          <label for="connection-type"> Connection Type </label>
          <div id="connection-type">
            <div class="checklist-item">
                <input
                id="design"
                v-model="connection.orchType"
                name="connection-type"
                type="radio"
                value="design"
                :checked="connection.orchType === 'design'"
                />
                <label for="design"> Design variable </label>
            </div>
            <div class="checklist-item">
                <input
                id="implicit"
                v-model="connection.orchType"
                name="connection-type"
                type="radio"
                value="implicit"
                :checked="connection.orchType === 'implicit'"
                />
                <label for="implicit"> Implicit variable or file </label>
            </div>
            <div class="checklist-item">
                <input
                id="setup"
                v-model="connection.orchType"
                name="connection-type"
                type="radio"
                value="setup"
                :checked="connection.orchType === 'setup'"
                />
                <label for="setup"> Setup variable or file </label>
            </div>
          </div>
        </div>
      </tab>
    </tabs-wrapper>
    <div id="buttons">
      <button :disabled="getValidity(validators) === 'invalid' || flowIsLocked == true" @click="saveNode">
        Save data
      </button>
      <button :disabled="flowIsLocked == true" @click="deleteNode">
        Delete
      </button>
    </div>
  </form>
</template>

<script>
import TabsWrapper from "./TabsWrapper.vue";
import Tab from "./Tab.vue";

export default {
  components: {
    "tabs-wrapper": TabsWrapper,
    tab: Tab,
  },
  props: {
    eleProps: { required: true },
    flowIsLocked: { required: true },
  },
  emits: ["update-connection", "delete-connection"],
  data() {
      return {
      connection: {
        id: this.eleProps.id,
        label: this.eleProps.label,
        orchType: this.eleProps.data.type,
      },
      validators: {
        label: "pending",
      },
      deleteThis: false, 
      submitThis: false,
     };
  },
  async mounted() {
    await this.runValidators()
  },
  methods: {
    emitUpdateConnection() { 
      const data = {
        id: this.connection.id,
        label: this.connection.label,
        orchType: this.connection.orchType,
      };
      this.$emit("update-connection", data);
    },
    async runValidators() { 
      this.validateInput(this.connection.label, "label");
    },
    async submitForm() {
      this.submitThis = true;
      if (this.deleteThis) {
        this.$emit("delete-connection", this.connection.id);
        return;
      } 
      await this.runValidators()
      this.emitUpdateConnection()
      console.warn("submitted form")
    },
    deleteNode() {
      this.deleteThis = true;
    },
    saveNode() {
      this.deleteThis = false;
    },
    getValidity(validators) {
      if (Object.values(validators).includes("invalid")) {
        return "invalid";
      } else if (Object.values(validators).includes("pending")) {
        return "pending";
      } else return "valid";
    },
    validateInput(val, key) {
      const matchChar_Name = /^[a-z0-9A-Z]([-a-z0-9A-Z]*[a-z0-9A-Z])?([\:\.\-\_][a-z0-9A-Z]([-a-z0-9A-Z]*[a-z0-9A-Z])?)*$/;
      if ((val === "" || val === null)) {
        this.validators[key] = "invalid";
      } else if (!(val === "" || val === null) && val.length > 100) {
        this.validators[key] = "invalid";
      } else if (key === "label" && matchChar_Name.test(val) == false) {
        this.validators[key] = "invalid";
      } else this.validators[key] = "valid";

      console.log(`validating ${key} json: ` + this.validators[key]);
    }
  }
};
</script>

<style scoped>
form {
  background-color: #ffffff;
}

.form-control {
  margin: 0;
  margin-top: 1rem;
  padding: 0;
}

.form-control.invalid input {
  border-color: red;
  border-style: solid;
  border-width: 1px;
}

.form-control.invalid textarea {
  border: 1px solid red;
}

.checklist-item{
  text-align: left; 
  margin-left: 5%; 
  margin-top: 1rem;
}

.checklist-item input{
  display:inline; 
  width: auto;
}

.form-warning {
  color: red;
  font: inherit;
  display: inline;
}

label {
  font-weight: bold;
}

.input-label {
  position:relative;
  cursor: pointer;
  font-weight: bold;
  color: black;
}
.input-label-disabled {
  color:  #4747478e;
}

h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

input {
  display: block;
  margin-left: 3%;
  width: 93%;
  font: inherit;
  margin-top: 0.5rem;
}

textarea {
  resize: none;
  display: block;
  margin-left: 3%;
  width: 93%;
}

#buttons {
  position: absolute;
  bottom: 30px;
  left: 5%; 
}

.form-control button, 
.form-control button:hover, 
.form-control button:active {
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: white;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.form-control button:disabled, 
.form-control button:disabled:hover{
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: white;
  background-color: transparent;
  color: #4747478e;
  cursor: pointer;
  font-weight: bold;
}

button {
  font: inherit;
  border: 1px solid #0076bb;
  background-color: #0076bb;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
}

button:hover,
button:active {
  border-color: #013274;
  background-color: #013274;
  color: white;
}

button:disabled, button:disabled:hover {
  border: 1px solid #0076bb70;
  background-color: #0076bb70;
  color: white;
}
</style>
