<template>
  <div v-show="visibility" @click="$emit('close')"></div>
  <dialog v-show="visibility" open>
    <section>
      <slot>
        <p>{{ eleprops?.id }}</p>
      </slot>
    </section>
    <!-- <menu>
      <slot name="actions">
         <button @click="$emit('close')">Close</button> 
      </slot>
    </menu> -->
  </dialog>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    eleprops: { required: false },
    visibility: { type: Boolean, required: true},
  },
  emits: ["close"],
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 15%;
  width: 70%;
  height: 80vh;
  z-index: 100;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: rgb(197, 197, 197);
}
</style>
