<template>
    <div class="message">
        <h1 > <font-awesome-icon icon="ban" size="lg" /> 
          <br> <br> Oops - it looks like you don't have access ... </h1>

        <h2> Contact us: <a href="mailto:support@dapta.com?subject=dapta 403error"> support@dapta.com </a> </h2>
   </div>
</template>

<style scoped>
.message {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

</style>