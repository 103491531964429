import { createWebHistory, createRouter } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';
import App from "./App.vue";
import Interface from "./components/Interface.vue";
import P403 from "./components/P403.vue";

const routes = [
    {
      path: '/',
      name: 'App',
      component: App, 
    },
    {
      path: '/interface',
      name: 'Interface',
      component: Interface, 
      meta: {
      requiresAuth: true
    }
    },
    {
      path: '/tutorial/:tutorial',
      name: 'Tutorial',
      component: Interface, 
      props: true,
      meta: {
      requiresAuth: true
    }
    },
    {
      path: '/403',
      name: '403',
      component: P403, 
    }
  ];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(authGuard)

export default router;