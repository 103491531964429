<template>
  <form @submit.prevent="submitForm">
    <tabs-wrapper>
      <tab :title="$t('message.Properties')">
        <div
          class="form-control"
          :class="{ invalid: validators.orchName === 'invalid' }"
        >
          <label for="component-name"> {{$t('message.Component_Name')}} </label>
          <input
            id="component-name"
            name="component-name"
            type="text"
            v-model.trim="component.orchName"
            @blur="validateInput(component.orchName, 'orchName').then(this.setAndValidateOrchApiInputFiles())"
            :disabled="flowIsLocked == true"
          />
          <div class="form-warning" v-if="validators.orchName === 'invalid'">
            {{$t('message.Please_enter_a_valid_run_name')}}
          </div>
        </div>
        <div
          class="form-control"
          :class="{ invalid: validators.orchApi === 'invalid' }"
        >
          <label for="component-api"> {{$t('message.API')}} </label>
          <select 
            name="component-api" 
            id="component-api" 
            v-model.trim="component.orchApi"
            @blur="validateInput(component.orchApi, 'orchApi').then(this.setAndValidateOrchApiInputFiles())"
            :disabled="flowIsLocked == true">
          </select>
          <div class="form-warning" v-if="validators.orchApi === 'invalid'">
            {{$t('message.Plz_sel_API')}}
          </div>
        </div>
        <div>
          <ul v-if="inputFiles" style="list-style: none; text-align: left; margin-top: 1rem; margin-bottom: 1rem;">
            <li v-for="item in inputFiles" :key="item.fileName" style="padding: 5px">
              <label class="input-label" :class="{ 'input-label-disabled': flowIsLocked == true || validators.orchName === 'invalid'}" :for="item.filename"> 
                <font-awesome-icon icon="file-import"/> {{item.filename}} 
              </label>
              <input :id="item.filename" type="file" :accept="item.accept" :disabled="flowIsLocked == true || validators.orchName === 'invalid'" @input="event => onFileImport(event, item.filename)" @blur="validateInput(inputFiles,'orchApiInputFiles')"/>
              <font-awesome-icon v-if="item.uploaded" icon="check-circle" style="margin-left: 5px; color: green"/>
              <font-awesome-icon v-if="item.failedChecks" icon="file-circle-exclamation" style="margin-left: 5px; color: red"/>
              <div v-if="item.uploaded" style="font-size:80%"> Modified (GMT): {{item.mtime}}, File Size: {{item.size}} bytes </div>
              <div v-if="!item.uploaded && item.required" style="font-size:80%; color: red"> File upload required. </div>
            </li>
          </ul>
        </div>
        <div
          class="form-control"
          :class="{ invalid: validators.orchOptions === 'invalid' }"
        >
          <label for="component-options"> {{$t('message.Options')}} </label>
          <textarea
            id="component-options"
            cols="70"
            rows="3"
            v-model.trim="component.orchOptions"
            @blur="validateJsonText(component.orchOptions, 'orchOptions')"
            :readonly="flowIsLocked == true"
          ></textarea>
          <div class="form-warning" v-if="validators.orchOptions === 'invalid'">
            Error: cannot parse to JSON object.
          </div>
        </div>
        <div class="checklist-item" :class="{ invalid: validators.isStart === 'invalid' }">
          <label for="isStart"> {{$t('message.Start_Node')}} </label>
          <input id="isStart" name="isStart" type="checkbox" v-model="component.isStart" :disabled="flowIsLocked == true || (this.editableWorkflowSettings[0] && component.isStart==false) || component.isDriver || (this.hasConnections[0] && this.eleProps.data.validity!=='invalid')"  @blur="validateCheckboxes(component.isStart,'isStart')">
          <div class="form-warning" v-if="validators.isStart === 'invalid'">
            Start node cannot have incoming data connections.
          </div>
        </div>
        <div class="checklist-item" :class="{ invalid: validators.isEnd === 'invalid' }">
          <label for="isEnd"> {{$t('message.End_Node')}} </label>
          <input  id="isEnd" name="isEnd" type="checkbox" v-model="component.isEnd" :disabled="flowIsLocked == true || (this.editableWorkflowSettings[1] && component.isEnd==false) || component.isDriver || (this.hasConnections[1] && this.eleProps.data.validity!=='invalid')"  @blur="validateCheckboxes(component.isEnd,'isEnd')">
          <div class="form-warning" v-if="validators.isEnd === 'invalid'">
            End node cannot have outgoing data connections.
          </div>
        </div>
        <div class="checklist-item" :class="{ invalid: validators.isDriver === 'invalid' }">
          <label for="isDriver"> {{$t('message.Driver')}} </label>
          <input  id="isDriver" name="isDriver" type="checkbox" v-model="component.isDriver" :disabled="flowIsLocked == true || (this.editableWorkflowSettings[2] && component.isDriver==false) || component.isStart || component.isEnd || (this.hasConnections[0] && this.eleProps.data.validity!=='invalid') || (this.hasConnections[1] && this.eleProps.data.validity!=='invalid')"  @blur="validateCheckboxes(component.isDriver,'isDriver')">
          <div class="form-warning" v-if="validators.isDriver === 'invalid'">
            Driver node cannot have any data connections.
          </div>
        </div>
      </tab>
      <tab :title="$t('message.Parameters')">
        <div
          class="form-control"
          :class="{ invalid: validators.orchParameters === 'invalid' }"
        >
          <label for="component-params"> {{$t('message.Parameters')}} </label>
          <textarea
            id="component-params"
            cols="70"
            rows="25"
            v-model.trim="component.orchParameters"
            @blur="validateJsonText(component.orchParameters, 'orchParameters')"
            :readonly="flowIsLocked == true"
          ></textarea>
          <div
            class="form-warning"
            v-if="validators.orchParameters === 'invalid'"
          >
            Error: cannot parse to JSON object.
          </div>
          <div  class="form-control">
              <label class="input-label" :class="{ 'input-label-disabled': flowIsLocked == true || validators.orchName === 'invalid' }" for="uploadParamfiles"> 
                <font-awesome-icon icon="file-import"/> {{$t('message.upload_user_input_files')}}  
              </label>
              <input id="uploadParamfiles" type="file" multiple :disabled="flowIsLocked == true || validators.orchName === 'invalid'" @input="event => onParamFilesImport(event)"/>
          </div>
          <button type="button" class="form-control" :disabled="flowIsLocked == true || validators.orchName === 'invalid'" @click="onParamFilesDelete()"> 
            <font-awesome-icon icon="trash"/> {{$t('message.delete_all_user_input_files')}}  
          </button>
        </div></tab
      >
      <tab :title="$t('message.Inputs')">
        <div
          class="form-control"
          :class="{ invalid: validators.orchInputs === 'invalid' }"
        >
          <label for="component-inputs"> {{$t('message.Input_Handles')}} </label>
          <textarea
            id="component-inputs"
            cols="70"
            rows="27"
            v-model.trim="component.orchInputs"
            @blur="validateJsonText(component.orchInputs, 'orchInputs')"
            :readonly="flowIsLocked == true"
          ></textarea>
          <div class="form-warning" v-if="validators.orchInputs === 'invalid'">
            Error: cannot parse to JSON object.
          </div>
        </div>
      </tab>

      <tab :title=" $t('message.Outputs') ">
        <div
          class="form-control"
          :class="{ invalid: validators.orchOutputs === 'invalid' }"
        >
          <label for="component-outputs"> {{$t('message.Output_Handles')}} </label>
          <textarea
            id="component-outputs"
            cols="70"
            rows="27"
            v-model.trim="component.orchOutputs"
            @blur="validateJsonText(component.orchOutputs, 'orchOutputs')"
            :readonly="flowIsLocked == true"
          ></textarea>
          <div class="form-warning" v-if="validators.orchOutputs === 'invalid'">
            Error: cannot parse to JSON object.
          </div>
        </div>
      </tab>
      
      <tab :title=" $t('message.Log') ">
        <div class="form-control">
          <label for="component-log"> {{$t('message.Events_Errors')}}  </label>
          <textarea
            id="component-log"
            cols="70"
            rows="27"
            readonly
            v-model.trim="eleProps.data.componentEventsLog"
          ></textarea>
          <button type="button" class="form-control" :disabled="(flowIsLocked == true && runIsPaused == false) || validators.orchName === 'invalid'" @click="onFilesDownload()"> 
            <font-awesome-icon icon="file-download"/> {{$t('message.download_files_snapshot')}}
          </button>
        </div>
      </tab>
    </tabs-wrapper>
    <div id="buttons">
      <button :disabled="getValidity(validators) === 'invalid' || flowIsLocked == true" @click="saveNode">
        {{$t('message.Save_data')}}
      </button>
      <button :disabled="flowIsLocked == true" @click="deleteNode">
        {{$t('message.Delete')}}
      </button>
    </div>
  </form>
</template>

<script>
import { inject, watch } from "vue";
import daptaUtils from "@/utils";
import TabsWrapper from "./TabsWrapper.vue";
import Tab from "./Tab.vue";
import componentApis from "./ComponentApis.json"
import Shared from './shared.vue'

export default {
  setup() {
    const k8sAdminAPIEndPoint = inject('back_end_admin_API_end_point');
    const userJWTToken = inject('userJWTToken').value;
    return {k8sAdminAPIEndPoint, userJWTToken}
  },
  components: {
    "tabs-wrapper": TabsWrapper,
    tab: Tab,
  },
  props: {
    eleProps: { required: true },
    allCompNames: { required: true },
    workflowSettings: { required: true }, 
    hasConnections: { required: true },
    flowIsLocked: { required: true },
    visibility: { required: true },
    runName: { required: true },
    logStream: { required: true },
  },
  emits: [
    "update-comp",
    "delete-comp",
    "node-mounted",
    "node-before-unmounted",
    "report-save",
    "delete-paramfiles",
    "get-user-storage"
  ],
  data() {
    return {
      component: {
        id: this.eleProps.id,
        orchName: this.eleProps.data.orchName,
        orchApi: this.eleProps.data.orchApi,
        orchOptions: this.Json2str(this.eleProps.data.orchOptions),
        orchParameters: this.Json2str(this.eleProps.data.orchParameters),
        orchInputs: this.Json2str(this.eleProps.data.orchInputs),
        orchOutputs: this.Json2str(this.eleProps.data.orchOutputs),
        isStart: this.eleProps.data.isStart,
        isEnd: this.eleProps.data.isEnd,
        isDriver: this.eleProps.data.isDriver,
      },
      validators: {
        orchName: "pending",
        orchApi: "pending",
        orchApiInputFiles: "pending",
        orchOptions: "pending",
        orchParameters: "pending",
        orchInputs: "pending",
        orchOutputs: "pending",
        isStart: "pending",
        isEnd: "pending",
        isDriver: "pending",
      },
      component_data_cache: {
        orchName: "",
        orchOptions: {},
        orchParameters: {},
        orchInputs: {},
        orchOutputs: {},
      },
      deleteThis: false, 
      submitThis: false,
      otherCompNames: [],
      editableWorkflowSettings: this.workflowSettings,
      allApis: componentApis,
      inputFiles: null,
      runIsPaused: false
      // tempFile: null,
      // tempFilePromise: "pending",
    };
  },
  async mounted() {
    // handle reply from the backend
    // window.ipc.on("READ_FILE", (payload) => {
    //   this.tempFilePromise = "resolved";
    //   this.tempFile = payload;
    //   //   console.log(this.tempFile);
    // });
    this.getOtherCompNames(this.component.orchName, this.allCompNames)
    this.setApiDropDown()
    this.getRunStatus()
    // validate the pre-populated fields
    await this.runValidators()
    console.warn("mounted")
    this.$emit('node-mounted');
  },
  beforeUnmount() { 
    if (this.deleteThis == false && this.submitThis == false && this.flowIsLocked == false) {
      if (this.visibility == false) {this.emitUpdateComponent()} // background component validation only
      console.warn("beforeUnmount")
      this.$emit("node-before-unmounted")
    }
  },
  watch: { 
    logStream: function() { 
      this.getRunStatus()
    } 
  },
  methods: {
    // NOTE: disable automated reading of included input (electron testing leftover)
    // readFile(path) {
    //   // ask backend to read file
    //   const payload = { path };
    //   window.ipc.send("READ_FILE", payload);
    // },
    getRunStatus() { 
      console.warn("getRunStatus in nodeform", this.logStream?.statuslog?.orchestrator?.status)
      this.runIsPaused = this.logStream?.statuslog?.orchestrator?.status ? this.logStream.statuslog.orchestrator.status === "paused" : false;  
    },
    getWorkflowSettings() { 
      if (this.eleProps.data.isStart ==true) {
        this.editableWorkflowSettings[0] = this.component.isStart;
      }
      if (this.eleProps.data.isEnd ==true) {
        this.editableWorkflowSettings[1] = this.component.isEnd;
      }
      if (this.eleProps.data.isDriver ==true) {
        this.editableWorkflowSettings[2] = this.component.isDriver;
      }
    },
    emitUpdateComponent(keepOpen=false, saveRun=false) { 
      const data = {
        id: this.component.id,
        orchName: this.component.orchName,
        orchApi: this.component.orchApi,
        orchApiInputFiles:this.inputFiles,
        orchOptions: this.component_data_cache.orchOptions,
        orchParameters: this.component_data_cache.orchParameters,
        orchInputs: this.component_data_cache.orchInputs,
        orchOutputs: this.component_data_cache.orchOutputs,
        validity: this.getValidity(this.validators),
        isStart: this.component.isStart,
        isEnd: this.component.isEnd,
        isDriver: this.component.isDriver,
      };
      this.$emit("update-comp", data, keepOpen, saveRun);
    },
    async runValidators() { 
      await this.validateInput(this.component.orchName, "orchName");
      this.validateInput(this.component.orchApi, "orchApi");
      await this.setAndValidateOrchApiInputFiles();
      await this.validateJsonText(this.component.orchOptions, "orchOptions");
      this.validateCheckboxes(this.component.isStart, "isStart");
      this.validateCheckboxes(this.component.isEnd, "isEnd");
      this.validateCheckboxes(this.component.isDriver, "isDriver");
      await this.validateJsonText(this.component.orchParameters, "orchParameters");
      await this.getParamInputFiles()
      await this.validateJsonText(this.component.orchInputs, "orchInputs");
      await this.validateJsonText(this.component.orchOutputs, "orchOutputs");
    },
    async setAndValidateOrchApiInputFiles() { 
      if (this.validators.orchName === "valid" && this.validators.orchApi === "valid") {
        await this.setOrchApiInputFiles();
        this.validateInput(this.inputFiles, "orchApiInputFiles");
      }
    },
    getOtherCompNames(name, allCompNames) { 
      console.log("Getting other component names.")
      this.otherCompNames = allCompNames.slice()
      this.otherCompNames.push("memcached", "controller")
      if (name !== null) {
        this.otherCompNames.splice(allCompNames.findIndex(e => e === name), 1)
      } 
    },
    setApiDropDown() { 
      const selector = document.getElementById('component-api');  
      for (const [key, values] of Object.entries(this.allApis)) {
        for (const version of values.versions) { 
          var option = document.createElement("option");
          option.text = key + ":" + version;
          selector.appendChild(option);
        }
      }
    },
    async submitForm() {
      this.submitThis = true;
      if (this.deleteThis) {
        this.$emit("delete-comp", this.component.id);
        return;
      } 
      await this.runValidators()
      this.emitUpdateComponent(false,true)
      console.warn("submitted form")
    },
    deleteNode() {
      this.deleteThis = true;
    },
    saveNode() {
      this.deleteThis = false;
    },
    getValidity(validators) {
      if (Object.values(validators).includes("invalid")) {
        return "invalid";
      } else if (Object.values(validators).includes("pending")) {
        return "pending";
      } else return "valid";
    },
    Json2str(obj) {
      return JSON.stringify(obj, null, "  ");
    },
    async validateInput(val, key) {
      const matchChar_Name = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
      const matchChar_Image = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?([\:\.][a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
      if ((val === "" || val === null) && !(key === "orchApiInputFiles")) {
        this.validators[key] = "invalid";
      } else if (!(val === "" || val === null) && !(key === "orchApiInputFiles") && val.length > 100) {
        this.validators[key] = "invalid";
      } else if (key === "orchName" && matchChar_Name.test(val) == false) {
        this.validators[key] = "invalid";
      } else if (key === "orchApi" && (matchChar_Image.test(val) == false || !val.includes(":"))) {
        this.validators[key] = "invalid";
      } else if (key === "orchApi" && !(val.split(":")[0] in this.allApis)) {
        this.validators[key] = "invalid";
      } else if (key === "orchName" && this.otherCompNames.indexOf(val) !== -1) { 
        this.validators[key] = "invalid";
      } else if (key === "orchApiInputFiles" && val) {
        this.validators[key] = this.inputFiles?.every(e => {
          if (e.required && e.uploaded) { return true }
          else if (!e.required) { return true }
        }) ? "valid" : "pending"  
      } else {
        this.validators[key] = "valid"

        // update component folder name if it exists
        const catchNameChange = key === "orchName" && this.component_data_cache[key] !=="" && this.component_data_cache[key] !== val
        const inputsUploaded = (this.inputFiles?.some(e => e.uploaded)) || this.component_data_cache.orchParameters?.user_input_files?.length >0 
        if (key === "orchName") {
          console.warn(catchNameChange, inputsUploaded)
        }
        if (catchNameChange && inputsUploaded) { 
          await this.onFolderRename(this.component_data_cache[key], val)
          this.emitUpdateComponent(true)
        }
        if (key === "orchName") this.component_data_cache["orchName"] = val

      };
      // console.log( this.allComponentNames.value)

      console.log(`validating ${key} json: ` + this.validators[key]);
    }, 
    async onCheckFileExists(filename) { 

      const resp = await fetch(this.k8sAdminAPIEndPoint + "/checkfilesexist?" + new URLSearchParams({
        file_name: filename,
        component_name: this.component.orchName,
        run_name: this.runName
        }), {
        method: "GET",
        headers: { "Authorization": `Bearer ${this.userJWTToken}`}
      }).then(Shared.methods.handleErrors)
        .then( response => { return response.text()})
        .then(response => { return JSON.parse(response)})

      if ("response" in resp) {
        console.log(`API check file ${filename} exists: ` + resp.response)
      }
      return resp
    },
    async setOrchApiInputFiles() {
      if (this.component.orchApi) {
        const apiName = this.component.orchApi.split(":")[0]
        if ((apiName in this.allApis) && ("inputs" in this.allApis[apiName])) {
          this.inputFiles = this.allApis[apiName].inputs
          for (let i = 0; i < this.inputFiles.length; i++) {
            const resp = await this.onCheckFileExists(this.inputFiles[i].filename)
            this.inputFiles[i].uploaded = resp.response
            this.inputFiles[i].mtime = resp.mtime
            this.inputFiles[i].size = resp.size
          }
        } else {
          this.inputFiles = null
        }
      } else {
          this.inputFiles = null
        }
    },
    async getParamInputFiles() {
      let ParamInputfiles
      if (this.component.orchName) {
        const resp = await this.onCheckFileExists("*")
        // filter out OrchApiInputFiles
        if (this.inputFiles) {
          const excludeFiles = this.inputFiles.map(a => a.filename)
          ParamInputfiles = resp.filter((el) => excludeFiles.indexOf(el.filename) == -1)
        } else { 
          ParamInputfiles = resp
        }
      } else {
        ParamInputfiles = [];
      }
      this.component_data_cache.orchParameters.user_input_files = ParamInputfiles
      this.component.orchParameters = this.Json2str(this.component_data_cache.orchParameters)
    },
    async IsJsonString(str) {
      let data = null;
      try {
        // console.log("IsJsonString run on: ", str);
        data = await JSON.parse(str);
      } catch (e) {
        console.log("Error occured parsing JSON: ", e);
        return [false, data];
      }
      return [true, data];
    },
    udateCompCache(key, value) {
      this.component_data_cache[key] = value;
      console.log("Updated the component cache.");
    },
    async validateJsonText(val, key) {
      //   console.log("val, key = ", val, key);
      const objChar = /[\{\}]/; // should check first and last characters only
      const avoidChar = /[^\w\.\-:"\/]/;

      if (!val || val === "" || val === "{}" || val === "null") {
        this.component[key] = "{}";
        this.validators[key] = "valid";
        this.udateCompCache(key, {});
      } else if (objChar.test(val)) {
        // try to parse an object
        console.log("this looks like a JSON string...");
        const [isJson, Jsondata] = await this.IsJsonString(val);
        if (isJson) {
          this.component[key] = this.Json2str(Jsondata);
          this.validators[key] = "valid";
          this.udateCompCache(key, Jsondata);
        } else this.validators[key] = "invalid";
      } else {
        console.log("This should be a JSON string!");
        this.validators[key] = "invalid";
      }
      console.log(`validating ${key} json: ` + this.validators[key]);
    },
    validateCheckboxes(val, key) { 
      this.getWorkflowSettings() //  disables options as required
      const incoming = this.hasConnections[0]
      const outgoing = this.hasConnections[1]
      let apiName = null
      if (this.component.orchApi && this.component.orchApi.includes(":")) { 
         apiName = this.component.orchApi.split(":")[0]
      }
      if (val) {
        // covers conditions required to invalidate or validate all start/end/driver nodes
        const conditionsForInvalid = (
          (key === "isStart" && incoming)
          || (key === "isStart" && this.component.isDriver)
          || (key === "isEnd" && outgoing)
          || (key === "isEnd" && this.component.isDriver)
          || (key === "isDriver" && (incoming || outgoing))
          || (key === "isDriver" && (this.component.isEnd || this.component.isStart))
          || (key === "isDriver" && apiName && !this.allApis[apiName].isDriver))
        const conditionsForValid = (
          (key === "isStart" && outgoing)
          || (key === "isStart" && this.component.isEnd)
          ||(key === "isEnd" && incoming)
          || (key === "isEnd" && this.component.isStart)
          || (key === "isDriver" && apiName && this.allApis[apiName].isDriver))
        if (conditionsForInvalid) {
          this.validators[key] = "invalid"
        } else { 
          this.validators[key] = conditionsForValid ? "valid" : "pending";
        }
      } else { 
        // cases where user deselects an option which caused another validator to be pending or invalid 
        if ((key === "isStart" || key === "isDriver") && this.component.isEnd) {
          this.validators[key] = "valid";
          this.validateCheckboxes(this.component.isEnd, "isEnd")
        }
        if ((key === "isEnd" || key === "isDriver") && this.component.isStart) {
          this.validators[key] = "valid";
          this.validateCheckboxes(this.component.isStart, "isStart")
        }
        if ((key === "isStart" || key === "isEnd") && this.component.isDriver) {
          this.validators[key] = "valid";
          this.validateCheckboxes(this.component.isDriver, "isDriver")
        }
        if (key === "isDriver" && apiName && !this.allApis[apiName].isDriver) { 
          this.validators[key] = "valid";
        }
        // case where no options are selected -> all other nodes should have in and out connections to be valid
        if ([this.component.isStart, this.component.isEnd, this.component.isDriver].some(e => e) == false) {
            this.validators[key] = (incoming && outgoing) ? "valid" : "pending"
        }      
      }
    },
    async uploadFile(file, filename) { 
      console.log("Uploading " + file.name + " as " + filename + " and type " + file.type);

      let data = new FormData()
      data.append('file', file)
      data.append('file_name', filename)
      data.append('component_name', this.component.orchName)
      data.append('run_name', this.runName)

      const resp = await fetch(this.k8sAdminAPIEndPoint + "/uploadfile", {
        method: "POST",
        headers: { "Authorization": `Bearer ${this.userJWTToken}`},
        body: data
      }).then(Shared.methods.handleErrors)
        .then( response => { return response.text()})
        .then(response => { return JSON.parse(response) })
      return resp
    },
    async onFileImport(event, filename) { 

      const file = event.target.files[0];

      // check file size before upload
      if (file.size > 1048576) {
        alert(`\u274C File is too large: Uploaded file ${file.name} exceeds 1Mb.`);
        return
      } 
      const resp = await this.uploadFile(file, filename)

      console.log("API upload file: ", resp)
      this.inputFiles.forEach(element => {
        if (element.filename === filename) {
          element.uploaded = resp.filesaved;
          element.mtime = resp.mtime
          element.size = resp.size
          element.failedChecks = resp.failed_checks
        }
      })

      if (resp.failed_checks) {
        this.$emit("report-save", resp.failed_checks);
      } else { 
        this.emitUpdateComponent(true, true) // save component once uploads exist
      }
    },
    async onParamFilesImport(event) { 
      const files = event.target.files;
      const serverfiles = this.component_data_cache.orchParameters.user_input_files
      const matchChar_Name = /^[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?([\.\-\_][a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?)*$/;

      if (files.length > 5){
          alert("\u274C Trying to upload too many files. Maximum of 5 files allowed (<1Mb each).");
          return
      }

      for (var j = 0; j < files.length; j++) {
        // check file before upload
        if (files[j].size > 1048576) {
          alert(`\u274C File is too large: Uploaded file ${files[j].name} exceeds 1Mb.`);
          continue
        }
        if (matchChar_Name.test(files[j].name) == false) {
          alert(`\u274C Illegal characters: Uploaded filename "${files[j].name}" contains illegal characters.\n Filenames should be a combination of a-z A-Z 0-9 .-_ only without spaces.`);
          continue
        }
        if (serverfiles.map(a=>a.filename).indexOf(files[j].name) == -1 && serverfiles.length >= 5){
          alert("\u274C Trying to upload too many files. Maximum of 5 files allowed (<1Mb each).\nUse 'delete all user input files' before uploading new files.");
          continue
        }
        if (this.inputFiles) {
          const excludeFiles = this.inputFiles.map(a => a.filename)
          if (excludeFiles.indexOf(files[j].name) > -1) {
            alert(`\u274C Reserved filename: ${files[j].name} can only be uploaded in the "Properties" tab.`);
            continue
          }
        }

        const resp = await this.uploadFile(files[j], files[j].name)
        if (resp.failed_checks) {
          this.$emit("report-save", resp.failed_checks);
        }

        this.$emit("get-user-storage") // show alert if user storage>=80%
        this.emitUpdateComponent(true, true) // save component once uploads exist
      }
      await this.getParamInputFiles()
    },
    async onParamFilesDelete() { 
      console.log("onParamFilesDelete")
      const filenames = this.component_data_cache.orchParameters.user_input_files.map(a=>a.filename)
      this.$emit("delete-paramfiles", this.component.orchName, "inputs", filenames);
    }, 
    async onFilesDownload() { 
      console.log("onFilesDownload")

      const data = new URLSearchParams({
        component_name: this.component.orchName,
        run_name: this.runName
      })
      let filename
      const resp = await fetch(this.k8sAdminAPIEndPoint + "/downloadcompzip?" + data, {
        method: "GET",
        headers: { "Authorization": `Bearer ${this.userJWTToken}`}
      }).then(Shared.methods.handleErrors)
        .then((res) => {
          filename = res.headers.get('Content-Disposition').split(';')[1].split('=')[1]
          return res.blob() 
        })
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(a.href)
      });
    }, 
    async onFolderRename(source, target) { 
      console.warn("RENAME FOLDER")

      const data = new FormData()
      data.append('source', source);
      data.append('target', target);
      data.append('run_name', this.runName);

      const resp = await fetch(this.k8sAdminAPIEndPoint + "/renamecompfolder", {
        method: "POST",
        headers: { "Authorization": `Bearer ${this.userJWTToken}`},
        body: data
      }).then(Shared.methods.handleErrors)
        .then(response => { return response.text() })
      console.log(resp)
    }
  }, 
  expose: ['getParamInputFiles']
};
</script>

<style scoped>
form {
  background-color: #ffffff;
}

.form-control {
  margin: 0;
  margin-top: 1rem;
  padding: 0;
}

.form-control.invalid input,
.form-control.invalid select{
  border-color: red;
  border-style: solid;
  border-width: 1px;
}

.form-control.invalid textarea {
  border: 1px solid red;
}

.checklist-item{
  text-align: left; 
  margin-left: 5%; 
  margin-top: 1rem;
}

.checklist-item input{
  display:inline; 
  width: auto;
}

.form-warning {
  color: red;
  font: inherit;
  display: inline;
}

label {
  font-weight: bold;
}

.input-label {
  position:relative;
  cursor: pointer;
  font-weight: bold;
  color: black;
}
.input-label-disabled {
  color:  #4747478e;
}

h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

input, 
select {
  display: block;
  margin-left: 3%;
  width: 93%;
  font: inherit;
  margin-top: 0.5rem;
}

textarea {
  resize: none;
  display: block;
  margin-left: 3%;
  width: 93%;
}

#buttons {
  position: absolute;
  bottom: 30px;
  left: 5%; 
}

.form-control button, 
.form-control button:hover, 
.form-control button:active {
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: white;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.form-control button:disabled, 
.form-control button:disabled:hover{
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: white;
  background-color: transparent;
  color: #4747478e;
  cursor: pointer;
  font-weight: bold;
}

button {
  font: inherit;
  border: 1px solid #0076bb;
  background-color: #0076bb;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
}

button:hover,
button:active {
  border-color: #013274;
  background-color: #013274;
  color: white;
}

button:disabled, button:disabled:hover {
  border: 1px solid #0076bb70;
  background-color: #0076bb70;
  color: white;
}
</style>
