

  // TODO import k8sAdminAPIEndPoint, userJWTToken here and remove from function calls
  import { defineComponent } from 'vue'
  import html2canvas from 'html2canvas';

  export default defineComponent({
    methods: {
      async handleErrors(response) {
        if (!response.ok) {
          const data = await response.json() 
          console.error(data)
          if (data.error_msg && data.error_msg == "Client is already connected") { 
            alert("\u274C You already have a web client open and multiple connections to an active Run are not allowed.\nClose this browser tab.")
          }
          throw Error(response.statusText);
        }
        return response;
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      async call_be_api_download(k8sAdminAPIEndPoint, userJWTToken, runName) {
        console.log("Download dapta_input.json");
        const data = new URLSearchParams({
          run_name: runName
        })
        const serverData = await fetch(k8sAdminAPIEndPoint + "/downloadjson?" + data, {
          method: "GET",
          headers: { "Authorization": `Bearer ${userJWTToken}` }
        }).then(this.handleErrors)
          .then((response) => {
            if (response.ok) {
              console.log(response)
              return response.json();
            }
          }).then((data) => {
            console.log(data);
            return data
          });
        return serverData
      }, 
      async call_get_Namespace_exists(k8sAdminAPIEndPoint, userJWTToken, endOnValue, getRunName = false) {
        console.log("Check if user k8s namespace exists.");
        let isNamespace
        let runName 
        const controller = new AbortController();
        const signal = controller.signal; 
        await fetch(k8sAdminAPIEndPoint + "/namespace", {
          method: "GET",
          headers: { "Authorization": `Bearer ${userJWTToken}` }, 
          signal: signal
        }).then(this.handleErrors)
          .then(async (response) => {
            const reader = response.body.getReader()
            return reader.read().then(async function processText({ done, value }) {
              if (done == true || isNamespace == endOnValue || ("any" === endOnValue && (isNamespace == true || isNamespace == false))) {
                console.warn("Stopping call_get_Namespace_exists.")
                controller.abort()
                return;
              }
              const decoder = new TextDecoder();
              const chunk = decoder.decode(value);
              const data = JSON.parse(chunk.replace(/'/g, '"'))
              console.log("isnamespace = ", data["isnamespace"])
              isNamespace = data["isnamespace"]
              runName = data["runName"]

              // Read some more, and call this function again
              return reader.read().then(processText);
            });
          }).catch(async () => {
            console.error("Fetching Namespace failed. Try again in 5s.")
            await this.sleep(5000);
            this.call_get_Namespace_exists(k8sAdminAPIEndPoint, userJWTToken, endOnValue)
          })
        if (getRunName) { 
          return [isNamespace, runName];
        } else return isNamespace
      }, 
      async call_be_api_terminate(k8sAdminAPIEndPoint, userJWTToken) { 
        console.log("Deleting user k8s namespace.");
        await fetch(k8sAdminAPIEndPoint + "/terminate", {
          method: "POST",
          headers: { "Authorization": `Bearer ${userJWTToken}` }
        }).then(this.handleErrors);
        let isNamespace = await this.call_get_Namespace_exists(k8sAdminAPIEndPoint, userJWTToken, false)
        if (isNamespace) {
          throw Error("Namespace still exists after termination -> exiting.")
        }
      },
      async call_be_api_getstoreitems(k8sAdminAPIEndPoint, userJWTToken, store) {
        console.log(`Get ${store} items list from server.`);
        const data = new URLSearchParams({
          store: store
        })
        const serverData = await fetch(k8sAdminAPIEndPoint + "/getstorelist?" + data, {
          method: "GET",
          headers: { "Authorization": `Bearer ${userJWTToken}` }
        }).then(this.handleErrors)
          .then((response) => {
            if (response.ok) {
              // console.log(response)
              return response.json();
            }
          }).then((data) => {
            // console.log(data);
            return data
          });

        for (let i = 0; i < serverData.length; i++) { 
          const dataPic = new URLSearchParams({
            file: "runThumbnail.png",
            run_name: serverData[i].name,
            subfolder: "dapta_FE"
          })
          const resp = await fetch(k8sAdminAPIEndPoint + "/getfiles?" + dataPic, {
            method: "GET",
            headers: { "Authorization": `Bearer ${userJWTToken}` }
          })
          if (resp.ok) {
            const b = await resp.blob()
            serverData[i].thumbnail = window.URL.createObjectURL(b)
          } else { 
            console.log("can't find runThumbnail...")
            // TODO: load placeholder image?
          }
        }
          
        return serverData
      },
      async call_be_api_delete_files(k8sAdminAPIEndPoint, userJWTToken, component, subfolder, filename, run) { 
        console.log("Delete files.");
        let data = new FormData()
        if (component) data.append('comp', component);
        if (subfolder) data.append('subfolder', subfolder);
        if (filename) data.append('file', filename);
        data.append('run_name', String(run));

        const resp = await fetch(k8sAdminAPIEndPoint + "/deletefile", {
          method: "POST",
          headers: { "Authorization": `Bearer ${userJWTToken}`},
          body: data
        }).then(this.handleErrors)
          .then(response => { return response.text() })
        console.log(resp)
      },
      async getScreenshot(){ 
        const canvas = await html2canvas(document.getElementById("vue-flow") || document.body, {scale: 0.20})
        return canvas
      },
      async uploadScreenshot(k8sAdminAPIEndPoint, userJWTToken, runName, canvasBlob) { 
        await canvasBlob.toBlob((blob) => {
            this.uploadFile(k8sAdminAPIEndPoint, userJWTToken, runName, blob, "runThumbnail.png")
          }, "image/png");
      },
      async uploadFile(k8sAdminAPIEndPoint, userJWTToken, runName, file, filename){ 
          console.log("Uploading " + file.name + " as " + filename + " and type " + file.type);

          let data = new FormData()
          data.append('file', file)
          data.append('file_name', filename)
          data.append('component_name', "")
          data.append('subfolder',"dapta_FE")
          data.append('run_name', runName)

          const resp = await fetch(k8sAdminAPIEndPoint + "/uploadfile", {
            method: "POST",
            headers: { "Authorization": `Bearer ${userJWTToken}`},
            body: data
          }).then(this.handleErrors)
            .then( response => { return response.text()})
            .then(response => { return JSON.parse(response)})
          return resp
        }
          }
        })
  
