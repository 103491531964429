<template>
  <div class="tabs">
    <ul class="tabs__headder">
      <li
        v-for="title in tabTitles"
        :key="title"
        :class="{ selected: title === selectedTitle , highlight: title === 'Log'}"
        @click="selectedTitle = title"
      >
        {{ title }}
      </li>
    </ul>
    <slot> </slot>
  </div>
</template>

<script>
import { ref, provide } from "vue";

export default {
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);

    provide("selectedTitle", selectedTitle);
    return {
      tabTitles,
      selectedTitle,
    };
  },
};
</script>

<style scoped>
.tabs {
  padding: 0;
  margin: 0;
}

.tabs__headder {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
  background-color: rgb(197, 197, 197);
}

.tabs__headder li {
  width: 85px;
  text-align: center;
  padding: 10px 10px;
  margin-left: 10px;
  border: 1px solid rgb(197, 197, 197);
  background-color: rgba(255, 255, 255, 0.5);
  color: rgb(66, 66, 66);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointed;
}

.tabs__headder li.selected {
  background-color: white;
  border-bottom-color: white;
}
.tabs__headder li.highlight {
  background-color: orange;
  border-color: rgb(133, 133, 133);
}

</style>
