<template>
  <dialog v-show="visibility" open>
    <section>
      <slot>
        <div style="padding: 10">
          <h3 style="text-align: left; padding-left: 20px;"> {{heading}} </h3>
          <input
              name="set-run-name"
              id="set-run-name"
              type="text"
              v-model.trim="userInputString"
              :placeholder="$t('message.Please_enter_a_valid_run_name')"
              required
              autofocus
              maxlength="40"
              pattern="^[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?$"
            />
        </div>
      </slot>
      <div id="smalldialogbuttons">
        <button class="basedialog" @click="async () => await callback(userInputString, callbackArgs)" :disabled="/^[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?$/.test(userInputString) == false" >{{$t('message.Ok')}}</button> 
        <button class="basedialog" @click="onCancel()"> {{$t('message.Cancel')}} </button> 
      </div>
    </section>
  </dialog>
</template>

<script>
export default {
  props: {
    visibility: { type: Boolean, required: true },
    callback: { required: true },
    callbackArgs: { required: false },
    heading: { required: true },
    onCancel: { required: true },
    runName: { required: false }
  },
  data() {
    return {
      userInputString: this.runName || ""
    }
  }
};

</script>

<style scoped>

dialog {
  position: fixed;
  top: 40vh;
  left: 30%;
  width: 40%;
  height: 20vh;
  z-index: 100;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: rgb(241, 241, 241);
}

#smalldialogbuttons {
  position: absolute;
  bottom: 5%;
  right: 5%; 
}
</style>
