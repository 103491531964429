<template>
  <div class="tab-content" v-show="title === selectedTitle" :class="{ highlight: title === 'Log'}">
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  props: ["title"],
  setup() {
    const selectedTitle = inject("selectedTitle");
    return {
      selectedTitle,
    };
  },
};
</script>

<style scoped>
.tab-content {
  width: 100%;
  height: 70vh;
  margin: 0;
  padding-top: 10px;
  background-color: white;
}

.highlight{
  background-color: rgb(133, 133, 133);
}
</style>
